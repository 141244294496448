<template lang="">
  <div>
      <DataTable
            :value="tableDatas"
            :selection.sync="selectedSupplier"

            selectionMode="single"
            dataKey="id"
            :scrollable="true"
            responsiveLayout="scroll"
            :paginator="true"
            class="p-datatable-customers"
            :rows="10"
            :filters="filters"
          >
          <Column header="Project Name" />
          <Column header="Project Code" />
            <!-- <Column>
              <template #body="slotProps">
                {{ slotProps.data.metaData[item.key] }}
              </template>
            </Column> -->
            <Column field="action" header="Action">
              <template #body="rowData">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon mr-1"
            
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon"
      
                >
                  <feather-icon icon="TrashIcon" />
                </b-button> -->
              </template>
            </Column>
          </DataTable>
  </div>
</template>
<script>
import {
BButton,
  BTable,
  BIcon,
} from "bootstrap-vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "@axios";
import InputText from "primevue/inputtext";
export default {
    data(){
      return{
      tableDatas: [],
      }
    },
    components: {
    BButton,
    BTable,
    BIcon,
    DataTable,
    Column,
    InputText,
  },
}
</script>
<style lang="">
  
</style>